exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-profiler-js": () => import("./../../../src/pages/profiler.js" /* webpackChunkName: "component---src-pages-profiler-js" */),
  "component---src-pages-services-data-profiling-js": () => import("./../../../src/pages/services/data-profiling.js" /* webpackChunkName: "component---src-pages-services-data-profiling-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-roi-analysis-js": () => import("./../../../src/pages/services/roi-analysis.js" /* webpackChunkName: "component---src-pages-services-roi-analysis-js" */),
  "component---src-pages-services-roi-monitoring-js": () => import("./../../../src/pages/services/roi-monitoring.js" /* webpackChunkName: "component---src-pages-services-roi-monitoring-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

